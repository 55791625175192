import './DigitsSection.scss';
import {FC, useEffect, useRef} from 'react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import useOnScreen from '../../../shared/hooks/useOnScreen';

interface DigitsSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link?: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
}

export const DigitsSection: FC<DigitsSectionProps> = ({activeLink, setActiveLink, zoomStyle}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.DIGITS) {
      setActiveLink(StartPageSections.DIGITS);
    }
  }, [isIntersecting]);

  useEffect(() => {
    const scrollWrapperElement = scrollWrapperRef.current;
    const wrapperElement = wrapperRef.current;
    if (!scrollWrapperElement || !wrapperElement) {
      return;
    }

    const scrollSection = (event: WheelEvent) => {
      const wrapperPosition = wrapperElement.getBoundingClientRect().y;
      if (isIntersecting) {
        const isScrollDown = event.deltaY > 0;
        if ((isScrollDown && wrapperPosition > 90) || (!isScrollDown && wrapperPosition < -70)) {
          wrapperElement.scrollIntoView();
        }
        if (
          (isScrollDown &&
            scrollWrapperElement.scrollLeft <
              scrollWrapperElement.scrollWidth - scrollWrapperElement.clientWidth - 1) ||
          (!isScrollDown && scrollWrapperElement.scrollLeft !== 0)
        ) {
          event.preventDefault();
          scrollWrapperElement.scrollBy({
            left: event.deltaY * 5,
            behavior: 'smooth',
          });
        }
      }
    };
    wrapperElement.addEventListener('wheel', scrollSection);

    return () => {
      wrapperElement.removeEventListener('wheel', scrollSection);
    };
  }, [isIntersecting]);

  return (
    <div id={StartPageSections.DIGITS} className="digits" ref={wrapperRef}>
      <div className="digits__zoom-container" style={zoomStyle}>
        <h2 className="section-heading">ЦИФРЫ</h2>
        <div className="digits__horizontal-scroll-wrapper" ref={scrollWrapperRef}>
          <div className="digits__content">
            <div className="digits__content-item">
              <p className="digits__content-heading">300+</p>
              <p className="digits__content-text">Реальных устройств iOS и Android</p>
              <img
                src="/assets/images/digits_devices.png"
                alt=""
                className="digits__content-image"
                style={{top: '-8.75rem', right: '1.25rem'}}
              />
            </div>
            <div className="digits__content-item">
              <p className="digits__content-heading">30%</p>
              <p className="digits__content-text">
                Экономия времени у тестировщиков и команды разработки
              </p>
              <img
                src="/assets/images/digits_work-time.png"
                alt=""
                className="digits__content-image"
                style={{top: '1.75rem', right: '-1.25rem'}}
              />
            </div>
            <div className="digits__content-item">
              <p className="digits__content-heading">160</p>
              <p className="digits__content-text">
                Часов в год освобождается у команды разработки при использовании Sunkey
              </p>
              <img
                src="/assets/images/digits_hours.png"
                alt=""
                className="digits__content-image"
                style={{top: '-8.75rem', right: '-2rem'}}
              />
            </div>
            <div className="digits__content-item">
              <p className="digits__content-heading">24/7</p>
              <p className="digits__content-text">Тестирование не прерывается</p>
              <img
                src="/assets/images/digits_continuously.png"
                alt=""
                className="digits__content-image"
                style={{top: '1.75rem', right: '-1.25rem'}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
