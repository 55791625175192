import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {App} from './App';
import {Start} from './pages/Start/Start';
import {PersonalDataAgreement} from './pages/PersonalDataAgreement/PersonalDataAgreement';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        index: true,
        element: <Start />,
      },
    ],
  },
  {
    path: 'personal-data-agreement',
    element: <PersonalDataAgreement />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
