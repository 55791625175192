import './FormInputField.scss';
import React, {FC, useCallback, useReducer, useState} from 'react';
import {ReactComponent as ClearInputIcon} from '@mts-ds/icons/web2/backspace/size-24-style-fill.svg';
import {ReactComponent as WarningIcon} from '@mts-ds/icons/web2/warning-circle/size-24-style-fill.svg';
import {ReactComponent as LockIcon} from '@mts-ds/icons/web2/lock/size-16-style-fill.svg';
import {formatFormInputPhoneNumber} from '../../shared/utils/Utils';

interface FormInputFieldProps {
  fieldName: string;
  callback: (...args: any) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  errors?: string;
  description?: string;
}

export const FormInputField: FC<FormInputFieldProps> = ({
  disabled,
  invalid,
  errors,
  fieldName,
  label,
  placeholder,
  description,
  callback,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useReducer(state => !state, false);
  const clearInput = useCallback(() => setInputValue(''), []);

  const onFormFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: {value},
      } = event;

      if (value && !isFocused) {
        setIsFocused();
      }

      if (fieldName === 'phone') {
        setInputValue(() => formatFormInputPhoneNumber(value));
      } else {
        setInputValue(() => value);
      }
    },
    [fieldName, isFocused],
  );

  const onFormFieldBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        target: {value},
      } = event;
      callback(fieldName === 'phone' ? formatFormInputPhoneNumber(value) : value, fieldName);
    },
    [callback, fieldName],
  );

  return (
    <div className="custom-input">
      {label && (
        <div className="custom-input__form-field-label">
          <span>{label}</span>
          {disabled && <LockIcon />}
        </div>
      )}
      <div className="custom-input__textfield-wrapper">
        <input
          className={`custom-input__textfield ${invalid || errors ? 'custom-input__textfield__invalid' : ''}`}
          onFocus={setIsFocused}
          value={inputValue}
          placeholder={placeholder}
          maxLength={fieldName === 'phone' ? 16 : undefined}
          onChange={onFormFieldChange}
          onBlur={onFormFieldBlur}
        />
        {inputValue && !isFocused && !errors && (
          <div onClick={clearInput} className="custom-input__textfieldIcon">
            <ClearInputIcon width={24} height={24} fill="#8d969f" />
          </div>
        )}
        {inputValue && errors && (
          <div className="custom-input__textfieldIcon">
            <WarningIcon width={24} height={24} fill="#F95721" />
          </div>
        )}
      </div>
      {errors && <div className="custom-input__errors">{errors}</div>}
      {description && !errors && (
        <div className="custom-input__form-field-subLabel">{description}</div>
      )}
    </div>
  );
};
