import {tokenStorageKey, userStorageKey} from '../constants/storageKeys';

export const getZoomValue = (windowWidth: number) => {
  if (windowWidth >= 1200) {
    return windowWidth / 1200;
  }
  if (windowWidth >= 960) {
    return windowWidth / 960;
  }
  if (windowWidth >= 640) {
    return windowWidth / 640;
  }
  if (windowWidth >= 480) {
    return windowWidth / 480;
  }

  return windowWidth / 320;
};

export const login = () => {
  localLogout();
  window.location.href = `${window.location.origin}/dashboard/`;
};

export const localLogout = () => {
  window.localStorage.removeItem(userStorageKey);
  window.localStorage.removeItem(tokenStorageKey);
};

export const validateEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const formatPhoneNumber = (phone: string) => phone.replace(/\D/g, '').replace(/^7/, '');

export const formatFormInputPhoneNumber = (str: string) => {
  let cleaned = ('' + str).replace(/\D/g, '');
  if (cleaned.startsWith('7')) {
    cleaned = cleaned.substring(1);
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return '+7 ' + match[1] + ' ' + match[2] + '-' + match[3] + '-' + match[4];
  }
  return str;
};

export const validatePhoneNumbers = /^\+\d{1,2}\s?\d{3}\s?\d{3}-\d{2}-\d{2}$/;
