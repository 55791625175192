import axios, {AxiosRequestConfig} from 'axios';
import env from '../env/environment';

const httpGet = <T>(url: string, config: AxiosRequestConfig = {}, customBaseUrl?: string) => {
  return axios
    .get<T>(url, {
      baseURL: customBaseUrl || env.product_api_url,
      ...config,
    })
    .catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
};

const httpPost = <T>(
  url: string,
  data: any = {},
  config: AxiosRequestConfig = {},
  customBaseUrl?: string,
) => {
  return axios
    .post<T>(url, data, {
      baseURL: customBaseUrl || env.product_api_url,
      ...config,
    })
    .catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
};

const httpPut = (
  url: string,
  data: any = {},
  config: AxiosRequestConfig = {},
  customBaseUrl?: string,
) => {
  return axios
    .put(url, data, {
      baseURL: customBaseUrl || env.product_api_url,
      ...config,
    })
    .catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
};

const ApiService = {httpGet, httpPost, httpPut};

export default ApiService;
