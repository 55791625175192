import {OrganizationResponse} from '../shared/models/organization.model';
import ApiService from './api.service';
import {AccessRequestBodyModel} from '../shared/models/accessRequest.model';
import env from '../env/environment';

const sendAccessRequest = (body: AccessRequestBodyModel) =>
  ApiService.httpPost<OrganizationResponse>(
    '/v1/beta/orders/new',
    body,
    undefined,
    env.core_api_url,
  );

const checkIfUserAlreadyInRequest = (phone: string) =>
  ApiService.httpGet<OrganizationResponse>(
    `/v1/beta/orders/exist/${phone}`,
    undefined,
    env.core_api_url,
  );

const AccessRequestsService = {sendAccessRequest, checkIfUserAlreadyInRequest};

export default AccessRequestsService;
