import './SolutionsSection.scss';
import clsx from 'clsx';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import useOnScreen from '../../../shared/hooks/useOnScreen';

interface SolutionsSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link?: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
}

export const SolutionsSection: FC<SolutionsSectionProps> = ({
  activeLink,
  setActiveLink,
  zoomStyle,
  zoom,
  isFF,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.SOLUTIONS) {
      setActiveLink(StartPageSections.SOLUTIONS);
    }
  }, [isIntersecting]);

  enum SolutionsTabIds {
    WEB = 'WEB',
    MOBILE = 'MOBILE',
  }

  const tabs = [
    {
      id: SolutionsTabIds.WEB,
      text: 'Для тестирования веб-сайтов',
      icon: 'monitor--red.svg',
    },
    {
      id: SolutionsTabIds.MOBILE,
      text: 'Для тестирования мобильных приложений',
      icon: 'phone--red.svg',
    },
  ];

  const [activeTabId, setActiveTabId] = useState(tabs[0].id);

  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    setInitialHeight(wrapperRef.current?.children[0].clientHeight ?? 0);
  }, [zoom]);

  const height = useMemo(
    () => (isFF ? initialHeight * zoom || 'auto' : 'auto'),
    [zoom, initialHeight],
  );

  return (
    <div id={StartPageSections.SOLUTIONS} className="solutions" ref={wrapperRef} style={{height}}>
      <div className="solutions__zoom-container" style={{...zoomStyle, height: 'auto'}}>
        <h2 className="section-heading">РЕШЕНИЯ</h2>
        <div className="solutions__tabs">
          <ul className="solutions__tabs-list">
            {tabs.map(tab => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={tab.id}
                className={clsx('solutions__tabs-list-item', {
                  'solutions__tabs-list-item--active': tab.id === activeTabId,
                })}
                onClick={() => setActiveTabId(tab.id)}
              >
                <span
                  className="solutions__tabs-icon"
                  style={{backgroundImage: `url(/assets/icons/${tab.icon})`}}
                />
                {tab.text}
              </li>
            ))}
          </ul>
          <div className="solutions__tabs-content-wrapper">
            {activeTabId === SolutionsTabIds.WEB && (
              <>
                <div className="solutions__tabs-content-item">
                  <p className="solutions__tabs-content-heading">
                    Автоматизированное тестирование интерфейса в&nbsp;браузерах
                  </p>
                  <ul className="solutions__tabs-content-text">
                    <li>Разворачивание виртуальных ресурсов</li>
                    <li>Использование Selenium Grid</li>
                    <li>Поддержка Edge, Chrome, Firefox, Opera</li>
                    <li>До 10 экземпляров браузеров в тесте</li>
                    <li>Различные версии браузеров</li>
                  </ul>
                </div>
                <div className="solutions__tabs-content-item">
                  <p className="solutions__tabs-content-heading">
                    Ручное кросс браузерное тестирование
                  </p>
                  <ul className="solutions__tabs-content-text">
                    <li>Настройка вашего окружения</li>
                    <li>4 браузера и 12 их версий</li>
                    <li>10 разрешений экрана</li>
                    <li>Взаимодействие со своим сайтом в браузере</li>
                  </ul>
                </div>
                <div className="solutions__tabs-content-item">
                  <div className="solutions__soon-badge">SOON</div>
                  <p className="solutions__tabs-content-heading">Автотестирование визуальное</p>
                  <ul className="solutions__tabs-content-text">
                    <li>Просмотр страницы в реальном размере устройства</li>
                    <li>
                      Инструмент сделает скриншоты приложения на разных устройствах, сравнит их и
                      выделит расхождения
                    </li>
                    <li>Возможность создавать дефект с медиафайлом в баг-трекере</li>
                  </ul>
                </div>
              </>
            )}
            {activeTabId === SolutionsTabIds.MOBILE && (
              <>
                <div className="solutions__tabs-content-item">
                  <p className="solutions__tabs-content-heading">Ручное мобильное тестирование</p>
                  <ul className="solutions__tabs-content-text">
                    <li>Доступно 300+ устройств</li>
                    <li>Добавление устройств, которые использует только ваша команда</li>
                    <li>Одновременное использование до 4 устройств</li>
                  </ul>
                </div>
                <div className="solutions__tabs-content-item">
                  <p className="solutions__tabs-content-heading">
                    Автоматизированное мобильное тестирование
                  </p>
                  <ul className="solutions__tabs-content-text">
                    <li>Поиск устройств по вашим критериям</li>
                    <li>
                      Возможность забронировать несколько устройств и установить на них приложения
                    </li>
                    <li>Запуск автотестов на выбранных устройствах</li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
