import './FeedbackSection.scss';
import {FC} from 'react';
import {MtsDsButton} from '@mts-ds/granat-react';
import {FEEDBACK_LINK} from '../../../shared/constants/constants';

interface FeedbackSectionProps {
  zoomStyle: React.CSSProperties;
  openBetaModal: () => void;
}

export const FeedbackSection: FC<FeedbackSectionProps> = ({zoomStyle, openBetaModal}) => {
  const openMailAgent = () => {
    window.location.href = FEEDBACK_LINK;
  };

  return (
    <div className="feedback">
      <div className="feedback__zoom-container" style={zoomStyle}>
        <div className="feedback__container">
          <img src="/assets/images/feedback.png" alt="" />
          <div className="feedback__content">
            <div className="feedback__info-block">
              <div className="feedback__info-block-text">
                <h2 className="section-heading">Готовы попробовать?</h2>
                <p>Запросите доступ к бета-версии</p>
              </div>
              <MtsDsButton size="l" onClick={openBetaModal}>
                получить доступ
              </MtsDsButton>
            </div>
            <div className="feedback__info-block">
              <div className="feedback__info-block-text">
                <h2 className="section-heading">остались вопросы?</h2>
                <p>Свяжитесь с нами</p>
              </div>
              <MtsDsButton size="l" onClick={openMailAgent} color="alternative">
                задать вопрос
              </MtsDsButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
