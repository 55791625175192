import './PersonalDataAgreement.scss';
import {MtsDsRoot} from '@mts-ds/granat-react';

export const PersonalDataAgreement = () => {
  return (
    <MtsDsRoot themeName="light">
      <div className="agreement-wrapper">
        <div className="agreement-text-wrapper">
          <embed src="/assets/files/user-agreement.pdf" type="application/pdf" />
        </div>
      </div>
    </MtsDsRoot>
  );
};
