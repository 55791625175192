import './UsersSection.scss';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import useOnScreen from '../../../shared/hooks/useOnScreen';

interface UsersSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link?: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
}

export const UsersSection: FC<UsersSectionProps> = ({
  activeLink,
  setActiveLink,
  zoomStyle,
  zoom,
  isFF,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.USERS) {
      setActiveLink(StartPageSections.USERS);
    }
  }, [isIntersecting]);

  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    setInitialHeight(wrapperRef.current?.children[0].clientHeight ?? 0);
  }, [zoom]);

  const height = useMemo(
    () => (isFF ? initialHeight * zoom || 'auto' : 'auto'),
    [zoom, initialHeight],
  );

  return (
    <div id={StartPageSections.USERS} className="users" ref={wrapperRef} style={{height}}>
      <div className="users__zoom-container" style={{...zoomStyle, height: 'auto'}}>
        <h2 className="section-heading">НАШИ ПОЛЬЗОВАТЕЛИ</h2>
        <div className="users__container">
          <div className="users__item">
            <img
              className="users__item-image"
              src="/assets/images/users_junior.svg"
              alt="Junior Logo"
            />
            <p className="users__item-text">
              МТС Junior — это подписка на полезные детские сервисы
            </p>
          </div>
          <div className="users__item">
            <img
              className="users__item-image"
              src="/assets/images/users_ocean.svg"
              alt="Ocean Logo"
            />
            <p className="users__item-text">
              МТС Ocean – платформа, объединяющая все сквозные ИТ-решения экосистемы МТС
            </p>
          </div>
          <div className="users__item">
            <img className="users__item-image" src="/assets/images/users_id.svg" alt="Id Logo" />
            <p className="users__item-text">МТС ID – единый аккаунт для всех сервисов экосистемы</p>
          </div>
          <div className="users__item">
            <img
              className="users__item-image"
              src="/assets/images/users_clatch.svg"
              alt="Clatch Logo"
            />
            <p className="users__item-text">Clatch – cервис для девушек и их друзей</p>
          </div>
        </div>
      </div>
    </div>
  );
};
