import {MtsDsInputGroup} from '@mts-ds/granat-react';
import React, {FC, SyntheticEvent, useState} from 'react';
import './AutocompleteDropdown.scss';

type AutocompleteDropdownProps = {
  label?: string;
  placeholder?: string;
  onSearchValueChange: (val: string) => any;
  onSelectValue: (val: any) => any;
  autocompleteOptions: any[];
  renderCustomOption?: (option: any) => React.ReactNode;
  errorText?: string;
  emptyText?: string;
  customDisplayProp?: string;
  customIdProp?: string;
  isError?: boolean;
  loading?: boolean;
  inputError?: string;
};

export const AutocompleteDropdown: FC<AutocompleteDropdownProps> = ({
  label,
  placeholder,
  onSearchValueChange,
  onSelectValue,
  autocompleteOptions,
  renderCustomOption,
  errorText,
  emptyText,
  customDisplayProp,
  customIdProp,
  isError,
  loading,
  inputError,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const onInputValueChange = (val: string) => {
    setSelectedValue(undefined);
    setSearchQuery(val);
    onSearchValueChange(val);
    if (val) {
      onSelectValue(val);
    }
  };

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value || !event.target.value.length) {
      onSelectValue(undefined);
    }
  };

  const renderHighlight = (option: React.ReactNode, highlight: string) => {
    if (typeof option === 'string') {
      const parts = option.split(new RegExp(`(${highlight})`, 'gi'));
      return (
        <span>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part.toLowerCase() === highlight.toLowerCase() ? <b>{part}</b> : part}
            </React.Fragment>
          ))}
        </span>
      );
    }
    const reactElementOption = {...(option as React.ReactElement)};
    const optionChildren = React.Children.map(reactElementOption.props.children, child => {
      if (child.props.slot !== 'name') {
        return child;
      }
      const parts: string[] = child.props.children.split(new RegExp(`(${highlight})`, 'gi'));
      const newChild = (
        <span>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part.toLowerCase() === highlight.toLowerCase() ? <b>{part}</b> : part}
            </React.Fragment>
          ))}
        </span>
      );

      return React.cloneElement(child, {
        ...child.props,
        children: newChild,
      });
    });

    return React.cloneElement(reactElementOption, {
      ...reactElementOption.props,
      children: optionChildren,
    });
  };

  const [selectedValue, setSelectedValue] = useState<any>();

  const selectValue = (value: any) => {
    setSelectedValue(value);
    onSelectValue(value);
    setSearchQuery('');
  };

  return (
    <div className="dropdown-wrapper">
      <MtsDsInputGroup
        valid={!inputError ? undefined : false}
        label={label}
        placeholder={placeholder}
        description={inputError}
        onChange={e => {
          onInputValueChange(
            ((e as SyntheticEvent<HTMLInputElement, Event>).target as HTMLInputElement)?.value,
          );
        }}
        onBlur={onInputBlur}
        value={
          selectedValue
            ? customDisplayProp
              ? selectedValue[customDisplayProp]
              : selectedValue.name
            : searchQuery || undefined
        }
      />
      {!!searchQuery && searchQuery.length >= 3 && !loading && (
        <div className="dropdown-wrapper__options">
          {!!autocompleteOptions.length &&
            autocompleteOptions.map(option => (
              <div
                key={customIdProp ? option[customIdProp] : option.id}
                className="dropdown-wrapper__option"
                onClick={() => selectValue(option)}
              >
                {renderHighlight(
                  renderCustomOption
                    ? renderCustomOption(option)
                    : customDisplayProp
                      ? option[customDisplayProp]
                      : option.name,
                  searchQuery,
                )}
              </div>
            ))}
          {!autocompleteOptions.length && isError && (
            <p className="dropdown-wrapper__info-option dropdown-wrapper__info-option--error">
              {errorText ?? 'Произошла ошибка. Попробуйте ещё раз'}
            </p>
          )}
          {!autocompleteOptions.length && !isError && (
            <p className="dropdown-wrapper__info-option">
              {emptyText ?? 'По вашему запросу ничего не найдено'}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
