import axios from 'axios';

const localEnvironment = {
  active_env: 'local',
  product_api_url: 'https://test.sunkey.mts-digital.ru/stk-product-service-ext/api',
  core_api_url: 'https://test.sunkey.mts-digital.ru/stk-portal-backend-auth-service-ext/api',
  product_owner_email: 'nspetras@mts.ru',
};

const environment = await initEnvironment();

export async function initEnvironment() {
  try {
    const envJson = await axios.get(`${process.env.PUBLIC_URL}/env/env.json`);
    return {
      product_api_url: envJson.data.product_api_url || localEnvironment.product_api_url,
      core_api_url: envJson.data.core_api_url || localEnvironment.core_api_url,
      product_owner_email: envJson.data.product_owner_email || localEnvironment.product_owner_email,
    };
  } catch (e) {
    console.error('Error load env file');
    return localEnvironment;
  }
}

export default environment;
