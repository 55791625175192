import './AdvantagesSection.scss';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import useOnScreen from '../../../shared/hooks/useOnScreen';

interface AdvantagesSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link?: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
}

export const AdvantagesSection: FC<AdvantagesSectionProps> = ({
  activeLink,
  setActiveLink,
  zoomStyle,
  zoom,
  isFF,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.ADVANTAGES) {
      setActiveLink(StartPageSections.ADVANTAGES);
    }
  }, [isIntersecting]);

  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    setInitialHeight(wrapperRef.current?.children[0].clientHeight ?? 0);
  }, [zoom]);

  const height = useMemo(
    () => (isFF ? initialHeight * zoom || 'auto' : 'auto'),
    [zoom, initialHeight],
  );

  return (
    <div id={StartPageSections.ADVANTAGES} className="advantages" ref={wrapperRef} style={{height}}>
      <div className="advantages__zoom-container" style={{...zoomStyle, height: 'auto'}}>
        <h2 className="section-heading">ПРЕИМУЩЕСТВА</h2>
        <div className="advantages__cards-container">
          <div className="advantages__card advantages__card--t2m">
            <p className="advantages__card-heading">T2M</p>
            <p className="advantages__card-text">
              Позволяет быстро провести тестирование и проверить гипотезы
            </p>
          </div>
          <div className="advantages__card advantages__card--team">
            <p className="advantages__card-heading">КОМАНДА</p>
            <p className="advantages__card-text">
              Помогает распределённой команде вместе работать над улучшением продукта
            </p>
          </div>
          <div className="advantages__card advantages__card--integration">
            <p className="advantages__card-heading">ИНТЕГРАЦИЯ</p>
            <p className="advantages__card-text">
              Интеграция с такими популярными инструментами, как Jira, GIT, Appium, Allure, SSO
            </p>
          </div>
          <div className="advantages__card advantages__card--development">
            <p className="advantages__card-heading">РАЗВИТИЕ</p>
            <p className="advantages__card-text">
              Мы готовы дорабатывать и развивать инструмент на основе ваших запросов
            </p>
          </div>
          <div className="advantages__card advantages__card--experience">
            <p className="advantages__card-heading">ОПЫТ</p>
            <p className="advantages__card-text">
              Опыт ведущих международных аналогов использован в нашем инструменте, чтобы ваш продукт
              становился лучше
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
