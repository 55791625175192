import './Footer.scss';
import {FC} from 'react';

interface FooterProps {
  zoom: number;
}

export const Footer: FC<FooterProps> = ({zoom}) => {
  return (
    <div className="footer">
      <div className="footer__zoom-container" style={{zoom}}>
        <div className="footer__product-info">
          <p className="footer__product-info-heading">Sunkey.Toolkit</p>
          <p className="footer__product-info-text">
            Инструмент для тестирования веб-интерфейсов и&nbsp;мобильных приложений iOS, Android
          </p>
        </div>
      </div>
    </div>
  );
};
