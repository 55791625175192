import '@mts-ds/granat-react-root/theme.css';
import '@mts-ds/granat-react-root/fonts.css';
import {useEffect, useState} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {MtsDsRoot} from '@mts-ds/granat-react-root';
import {Outlet} from 'react-router-dom';
import {Footer} from './components/Footer/Footer';
import {getZoomValue} from './shared/utils/Utils';

export const App = () => {
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setZoom(getZoomValue(window.innerWidth));

    const handleResize = () => {
      setZoom(getZoomValue(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MtsDsRoot themeName="light">
      <Outlet />
      <Footer zoom={zoom} />
    </MtsDsRoot>
  );
};
