import './Header.scss';
import {MtsDsButton} from '@mts-ds/granat-react';
import {ReactComponent as CloseIcon} from '@mts-ds/icons/web2/cross/size-24-style-outline.svg';
import {ReactComponent as MenuIcon} from '@mts-ds/icons/web2/menu/size-32-style-outline.svg';
import clsx from 'clsx';
import {FC, useEffect, useReducer, useState} from 'react';
import {StartPageSections} from '../../shared/enums/startPageSections.enum';

const headerLinks = [
  {title: 'Главная', link: StartPageSections.MAIN},
  {title: 'Преимущества', link: StartPageSections.ADVANTAGES},
  {title: 'О продукте', link: StartPageSections.ABOUT},
  {title: 'Цифры', link: StartPageSections.DIGITS},
  {title: 'Наши пользователи', link: StartPageSections.USERS},
  {title: 'Решения', link: StartPageSections.SOLUTIONS},
];

interface HeaderProps {
  activeLink?: StartPageSections;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
}

export const Header: FC<HeaderProps> = ({activeLink, zoomStyle, zoom, isFF}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const [isOpened, toggleIsOpened] = useReducer(val => !val, false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 80);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isOpened && window.innerWidth > 1200) {
        toggleIsOpened();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isOpened]);

  const toggleBurgerMenu = () => {
    document.body.style.maxHeight = !isOpened ? '100vh' : 'unset';
    document.body.style.overflow = !isOpened ? 'hidden' : 'visible';
    toggleIsOpened();
  };

  const headerLinksList = headerLinks.map(link => {
    return (
      <li
        key={link.title}
        className={clsx('header__navigation-list-item', {
          'header__navigation-list-item--active':
            !isOpened && (!activeLink || activeLink === link.link),
        })}
      >
        <a
          onClick={() => isOpened && toggleBurgerMenu()}
          className="header__navigation-link"
          href={`#${link.link}`}
        >
          {link.title}
        </a>
      </li>
    );
  });

  return (
    <>
      <div
        className={clsx('header', {
          'header--scrolled': isScrolled || isOpened,
          'header--opened': isOpened,
        })}
        style={{height: isFF ? `calc(3.375rem * ${zoom})` : 'auto'}}
      >
        <div className="header__zoom-container" style={zoomStyle}>
          {isOpened && (
            <div
              style={
                isFF
                  ? {
                      position: 'absolute',
                      top: `calc(-2.75rem / ${zoom})`,
                      right: `calc((100vw - 250px - 1.5rem) / ${zoom})`,
                    }
                  : {position: 'absolute', top: '0', right: '0'}
              }
            >
              <MtsDsButton color="ghost" size="m" onClick={toggleBurgerMenu}>
                <div slot="icon">
                  <CloseIcon />
                </div>
              </MtsDsButton>
            </div>
          )}
          <div className="header__navigation-wrapper">
            <nav className="header__navigation">
              <ul className="header__navigation-list">{headerLinksList}</ul>
            </nav>
          </div>
        </div>
      </div>
      {!isOpened && (
        <div className="burger-menu-header">
          <MtsDsButton color="ghost" size="xl" onClick={toggleBurgerMenu}>
            <div slot="icon">
              <MenuIcon />
            </div>
          </MtsDsButton>
        </div>
      )}
      {isOpened && <div className="body-overlay" onClick={toggleBurgerMenu} />}
    </>
  );
};
