import './Start.scss';
import {useEffect, useMemo, useReducer, useState} from 'react';
import {BetaRequestModal} from '../../components/BetaRequestModal/BetaRequestModal';
import {Header} from '../../components/Header/Header';
import {StartPageSections} from '../../shared/enums/startPageSections.enum';
import {getZoomValue} from '../../shared/utils/Utils';
import {AboutSection} from './AboutSection/AboutSection';
import {AdvantagesSection} from './AdvantagesSection/AdvantagesSection';
import {DigitsSection} from './DigitsSection/DigitsSection';
import {FeedbackSection} from './FeedbackSection/FeedbackSection';
import {MainSection} from './MainSection/MainSection';
import {SolutionsSection} from './SolutionsSection/SolutionsSection';
import {UsersSection} from './UsersSection/UsersSection';

export const Start = () => {
  const [activeLink, setActiveLink] = useState<StartPageSections | undefined>(
    StartPageSections.MAIN,
  );

  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setZoom(getZoomValue(window.innerWidth));

    const handleResize = () => {
      setZoom(getZoomValue(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isFF = useMemo(() => navigator.userAgent.includes('Firefox'), []);

  const ffZoomStyle: React.CSSProperties = useMemo(
    () => ({
      transform: `scale(${zoom})`,
      width: `calc(100vw / ${zoom})`,
      height: `calc(100% / ${zoom})`,
      boxSizing: 'border-box',
      transformOrigin: 'top left',
    }),
    [zoom],
  );

  const [isBetaModalOpened, toggleIsBetaModalOpened] = useReducer(val => !val, false);

  return (
    <>
      <Header
        activeLink={activeLink}
        zoomStyle={isFF ? ffZoomStyle : {zoom}}
        zoom={zoom}
        isFF={isFF}
      />
      <div className="start-page">
        <MainSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          zoom={zoom}
          isFF={isFF}
          openBetaModal={toggleIsBetaModalOpened}
        />
        <AdvantagesSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          zoom={zoom}
          isFF={isFF}
        />
        <AboutSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          zoom={zoom}
          isFF={isFF}
        />
        <DigitsSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
        />
        <UsersSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          zoom={zoom}
          isFF={isFF}
        />
        <SolutionsSection
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          zoom={zoom}
          isFF={isFF}
        />
        <FeedbackSection
          zoomStyle={isFF ? ffZoomStyle : {zoom}}
          openBetaModal={toggleIsBetaModalOpened}
        />
      </div>
      {isBetaModalOpened && <BetaRequestModal closeModal={toggleIsBetaModalOpened} />}
    </>
  );
};
