import './ModalCard.scss';
import {FC} from 'react';
import {ReactComponent as DoneIcon} from '@mts-ds/icons/web2/check-circle/size-44-style-fill.svg';
import {ReactComponent as InfoIcon} from '@mts-ds/icons/web2/info-circle/size-44-style-fill.svg';
import {ReactComponent as ErrorIcon} from '@mts-ds/icons/web2/cross-circle/size-44-style-fill.svg';

interface ModalCardProps {
  type: 'info' | 'error' | 'done';
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const ModalCard: FC<ModalCardProps> = ({type, title, subtitle, children}) => {
  return (
    <div className="modal-card-overlay">
      <div className="modal-card">
        <div className="modal-card__header">
          <div className="modal-card__icon">
            {type === 'done' && <DoneIcon fill="var(--color-accent-positive)" />}
            {type === 'info' && <InfoIcon fill="var(--color-accent-active)" />}
            {type === 'error' && <ErrorIcon fill="var(--color-accent-negative)" />}
          </div>
          <div className="modal-card__heading">
            <h1 className="modal-card__title">{title}</h1>
            {subtitle && <p className="modal-card__subtitle">{subtitle}</p>}
          </div>
        </div>
        <div className="modal-card__body">{children}</div>
      </div>
    </div>
  );
};
