import './BetaRequestModal.scss';
import {ModalPage, MtsDsButton} from '@mts-ds/granat-react';
import {FC, useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import OrganizationsService from '../../services/organizations.service';
import {ModalCard} from '../../shared/components/ModalCard/ModalCard';
import {Organization} from '../../shared/models/organization.model';
import AccessRequestsService from '../../services/accessRequest.service';
import {formatPhoneNumber, validateEmail, validatePhoneNumbers} from '../../shared/utils/Utils';
import {FormInputField} from '../FormInputField/FormInputField';
import {PersonalAgreement} from '../PersonalAgreement/PersonalAgreement';
import {OrganizationSearch} from '../OrganizationSearch/OrganizationSearch';

interface FormElement<T> {
  value: T;
  isValid: boolean;
  errorText?: string;
  isTouched: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
}

interface BetaRequestModalProps {
  closeModal: () => void;
}

export interface FormState {
  commonName: FormElement<string>;
  phone: FormElement<string>;
  email: FormElement<string>;
  isCompany: FormElement<boolean>;
  organization?: FormElement<Organization | null>;
  personalDataAgreement: FormElement<boolean>;
}

export const BetaRequestModal: FC<BetaRequestModalProps> = ({closeModal}) => {
  const requiredFieldErrorText = 'Обязательное поле';

  const [searchTerm, setSearchTerm] = useState('');

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const [isOrganizationError, setIsOrganizationError] = useState(false);

  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);

  const [isSuccessResponse, setIsSuccessResponse] = useReducer(state => !state, false);

  const [isRejectedResponse, setIsRejectedResponse] = useReducer(state => !state, false);

  const [requestModalStateHidden, setRequestModalStateHidden] = useReducer(state => !state, false);

  const [formState, setFormState] = useState<FormState>({
    commonName: {
      value: '',
      isValid: true,
      isTouched: false,
      label: 'Как к вам обращаться',
      placeholder: 'Введите имя и фамилию',
    },
    phone: {
      value: '',
      isValid: true,
      isTouched: false,
      label: 'Номер телефона',
      placeholder: 'Введите номер',
      description: 'Доступ будет привязан к указанному телефону',
    },
    email: {
      value: '',
      isValid: true,
      isTouched: false,
      label: 'Ваша рабочая почта',
      placeholder: 'Введите email',
    },
    isCompany: {value: true, isValid: true, isTouched: true},
    organization: {value: null, isValid: true, isTouched: false},
    personalDataAgreement: {value: false, isValid: false, isTouched: false},
  });

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      setIsOrganizationLoading(true);
      OrganizationsService.getOrganizations(searchTerm)
        .then(res => {
          setOrganizations(res.data.suggestions);
          setIsOrganizationError(false);
        })
        .catch(() => setIsOrganizationError(true))
        .finally(() => setIsOrganizationLoading(false));
    } else {
      setOrganizations([]);
    }
  }, [searchTerm]);

  const renderOrganizationAutocompleteItem = (option: Organization) => (
    <>
      <p className="autocomplete-option-title" slot="name">
        {option.name}
      </p>
      <p className="autocomplete-option-info">
        <span>{option.inn}</span>
        <div className="devider" />
        <span className="autocomplete-option-info__address">{option.address}</span>
      </p>
    </>
  );

  const validateRequiredField = useCallback(
    (value: string | Organization | boolean, field: keyof typeof formState): string => {
      return !value &&
        (['commonName', 'email'].includes(field) ||
          (field === 'organization' && formState.isCompany.value) ||
          field === 'personalDataAgreement')
        ? requiredFieldErrorText
        : '';
    },
    [formState, requiredFieldErrorText],
  );

  const validatePhoneField = useCallback(
    (value: string): string => {
      if (!value || value.length <= 1) {
        return requiredFieldErrorText;
      }
      if (value.length < 16 || !validatePhoneNumbers.test(value)) {
        return 'Некорректный номер телефона';
      }
      AccessRequestsService.checkIfUserAlreadyInRequest(formatPhoneNumber(value))
        .then(response => {
          if (response.data) {
            setFormState({
              ...formState,
              phone: {
                ...formState.phone,
                isValid: false,
                errorText: 'Мы уже получили вашу заявку',
              },
            });
          }
        })
        .catch(() => {});

      return '';
    },
    [formState.phone],
  );

  const validateEmailField = useCallback(
    (value: string) => {
      if (!value || value?.length === 0) {
        return requiredFieldErrorText;
      }
      const isValid = validateEmail.test(value);
      return isValid ? '' : 'Некорректный email';
    },
    [requiredFieldErrorText, validateEmail],
  );

  const onInputChange = useCallback(
    (value: string | Organization | boolean, field: keyof typeof formState) => {
      const newFormState = {...formState};
      let fieldError = '';
      if (typeof value === 'string' && value.length > 255) {
        fieldError = 'Максимум 255 символов';
      } else if (field === 'phone') {
        fieldError = validatePhoneField(value as string);
      } else if (field === 'email') {
        fieldError = validateEmailField(value as string);
      } else {
        fieldError = validateRequiredField(value, field);
      }
      (newFormState[field] as FormElement<typeof value>) = {
        ...newFormState[field],
        value,
        isValid: !fieldError,
        errorText: fieldError,
        isTouched: true,
      };
      setFormState(() => newFormState);
    },
    [formState, validatePhoneField, validateEmailField, validateRequiredField],
  );

  const toggleOrganizationFormField = useCallback(() => {
    const organizationFormFiled = formState.organization
      ? undefined
      : {value: null, isValid: true, isTouched: false};
    setFormState(old => ({
      ...old,
      isCompany: {
        ...old.isCompany,
        value: !old.isCompany.value,
      },
      organization: organizationFormFiled,
    }));
  }, [formState.organization]);

  const sendData = useCallback(() => {
    const isFormValid = Object.entries(formState).every(([key, value]) => {
      /* необязательное поле */
      if (key === 'isCompany' || (key === 'organization' && !formState[key])) {
        return true;
      }
      const {isValid, isTouched} = value as FormElement<any>;
      return isValid && isTouched;
    });

    if (isFormValid) {
      /* если форма заполнена корректно, направляем заявку */
      AccessRequestsService.sendAccessRequest({
        commonName: formState.commonName.value,
        phone: formatPhoneNumber(formState.phone.value),
        email: formState.email.value,
        isCompany: !!formState.organization,
        companyName: formState.organization?.value?.name || '',
        companyInn: formState.organization?.value?.inn || '',
      })
        .then(() => {
          setIsSuccessResponse();
          setRequestModalStateHidden();
        })
        .catch(() => {
          setIsRejectedResponse();
          setRequestModalStateHidden();
        });
    } else {
      /* если нет, заполняем форму ошибками */
      Object.keys(formState).forEach(key => {
        const field = key as keyof FormState;

        setFormState(prevState => {
          return {
            ...prevState,
            [field]: prevState[field]
              ? {
                  ...prevState[field],
                  isTouched: true,
                  isValid: prevState[field]?.isValid ? prevState[field]?.isValid : false,
                  errorText:
                    prevState[field]?.isValid && prevState[field]?.isTouched
                      ? prevState[field]?.errorText
                      : 'Обязательное поле',
                }
              : prevState[field],
          };
        });
      });
    }
  }, [
    formState,
    setIsSuccessResponse,
    setIsRejectedResponse,
    setRequestModalStateHidden,
    formatPhoneNumber,
  ]);

  const formFields = useMemo(
    () =>
      Object.entries(formState).reduce(
        (
          acc: {
            key: keyof FormState;
            value: FormElement<string> | FormElement<boolean> | FormElement<Organization | null>;
          }[],
          [key, value],
        ) => {
          // eslint-disable-next-line no-prototype-builtins
          if (value?.hasOwnProperty('label')) {
            acc.push({key: key as keyof FormState, value});
          }
          return acc;
        },
        [],
      ),
    [formState],
  );

  return (
    <>
      {!requestModalStateHidden && (
        <ModalPage
          open={true}
          onClose={closeModal}
          size="s"
          title="Заявка на доступ"
          subtitle="Поделитесь информацией о себе, чтобы мы могли с вами связаться в ближайшее время"
        >
          <div className="beta-modal-body">
            <div className="beta-modal-body__form">
              {formFields.length &&
                formFields.map(({key, value: {label, placeholder, description}}) => (
                  <FormInputField
                    key={key}
                    fieldName={key}
                    callback={onInputChange}
                    label={label}
                    errors={formState[key]?.errorText}
                    placeholder={placeholder}
                    description={description}
                  />
                ))}
            </div>

            <OrganizationSearch
              toggleOrganizationFormField={toggleOrganizationFormField}
              invalid={!formState.isCompany.isValid}
              organization={formState.organization}
              setSearchTerm={setSearchTerm}
              callback={onInputChange}
              organizations={organizations}
              renderOrganizationAutocompleteItem={renderOrganizationAutocompleteItem}
              isError={isOrganizationError}
              loading={isOrganizationLoading}
              inputError={formState.organization?.errorText}
              field="organization"
            />

            <PersonalAgreement
              callback={onInputChange}
              field="personalDataAgreement"
              invalid={
                !formState.personalDataAgreement.isValid &&
                formState.personalDataAgreement.isTouched
              }
            />
            <div className="beta-modal-body__actions">
              <MtsDsButton size="l" fluid onClick={sendData}>
                Отправить
              </MtsDsButton>
            </div>
          </div>
        </ModalPage>
      )}

      {isSuccessResponse && (
        <ModalCard type="done" title="Мы получили вашу заявку" subtitle="Скоро свяжемся с вами">
          <MtsDsButton onClick={closeModal} fluid>
            Закрыть
          </MtsDsButton>
        </ModalCard>
      )}

      {isRejectedResponse && (
        <ModalCard type="error" title="Произошла ошибка" subtitle="Попробуйте ещё раз">
          <MtsDsButton onClick={closeModal} fluid>
            Закрыть
          </MtsDsButton>
        </ModalCard>
      )}
    </>
  );
};
