import './MainSection.scss';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {MtsDsBadge, MtsDsButton} from '@mts-ds/granat-react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import {login} from '../../../shared/utils/Utils';
import useOnScreen from '../../../shared/hooks/useOnScreen';

interface MainSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
  openBetaModal: () => void;
}

export const MainSection: FC<MainSectionProps> = ({
  activeLink,
  setActiveLink,
  zoomStyle,
  zoom,
  isFF,
  openBetaModal,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.MAIN) {
      setActiveLink(StartPageSections.MAIN);
    }
  }, [isIntersecting]);

  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    setInitialHeight(contentWrapperRef.current?.clientHeight ?? 0);
  }, [zoom]);

  const height = useMemo(
    () => (isFF ? initialHeight * zoom || 'auto' : 'auto'),
    [zoom, initialHeight],
  );

  return (
    <div id={StartPageSections.MAIN} className="main" ref={wrapperRef} style={{height}}>
      <div style={zoomStyle} className="main__zoom-container">
        <div className="main__wrapper" ref={contentWrapperRef}>
          <div className="main__text">
            <div className="main__heading-wrapper">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  columnGap: '1.5rem',
                }}
              >
                <h1 className="main__heading">Sunkey Toolkit</h1>
                <MtsDsBadge backgroundColor="color-control-primary-active" textColor="light">
                  Бета-версия
                </MtsDsBadge>
              </div>
              <p className="main__subheading">
                Мобильное и кроссбраузерное тестирование ваших продуктов
              </p>
            </div>
            <ul className="main__description">
              <li>
                Тестируйте веб-интерфейсы и мобильные приложения в&nbsp;режиме реального времени
                и&nbsp;через API
              </li>
              <li>
                Наш инструмент позволяет использовать более&nbsp;100&nbsp;конфигураций браузеров
                и&nbsp;более&nbsp;300&nbsp;реальных устройств
              </li>
            </ul>
          </div>
          <div className="main__buttons">
            <MtsDsButton size="l" onClick={openBetaModal}>
              Получить доступ
            </MtsDsButton>
            <MtsDsButton size="l" color="alternative" onClick={login}>
              Уже есть доступ
            </MtsDsButton>
          </div>
          <img className="main__image" src="/assets/images/start-page_main.png" alt="" />
        </div>
      </div>
    </div>
  );
};
