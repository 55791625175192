import './AboutSection.scss';
import clsx from 'clsx';
import {FC, useEffect, useRef, useState, useMemo} from 'react';
import {StartPageSections} from '../../../shared/enums/startPageSections.enum';
import useOnScreen from '../../../shared/hooks/useOnScreen';
import {accordionImageNames} from './constants/constants';

const aboutSections = [
  {title: 'РАЗНООБРАЗИЕ УСТРОЙСТВ', text: '300+ реальных устройств iOS и Android', index: 0},
  {
    title: 'ИНДИВИДУАЛЬНЫЕ НАСТРОЙКИ',
    text: 'Конфигурация устройства в нужное для тестирования состояние',
    index: 1,
  },
  {
    title: 'ДОСТУПНОСТЬ 24/7',
    text: 'Тестирование не прерывается ночью или в выходные дни',
    index: 2,
  },
  {
    title: 'КОНФИДЕНЦИАЛЬНОСТЬ',
    text: 'Ваши данные никто не увидит. После сессий устройства очищаются автоматически',
    index: 3,
  },
  {
    title: 'УДАЛЁННОСТЬ',
    text: 'Тесты можно отладить удалённо. Это позволяет распределённой команде работать над продуктом вместе',
    index: 4,
  },
  {
    title: 'CI/CD',
    text: 'Российский инструмент создан с учётом опыта лучших международных аналогов',
    index: 5,
  },
];

interface AboutSectionProps {
  activeLink?: StartPageSections;
  setActiveLink: (link?: StartPageSections) => void;
  zoomStyle: React.CSSProperties;
  zoom: number;
  isFF: boolean;
}

export const AboutSection: FC<AboutSectionProps> = ({
  activeLink,
  setActiveLink,
  zoomStyle,
  zoom,
  isFF,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const zoomWrapperRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isIntersecting && activeLink !== StartPageSections.ABOUT) {
      setActiveLink(StartPageSections.ABOUT);
    }
  }, [isIntersecting]);

  const [openedFieldIndex, setOpenedFieldIndex] = useState(-1);

  const getAccordionContentWrapper = () => (
    <div
      className="about__accordion-content-wrapper"
      style={{opacity: openedFieldIndex > -1 ? 1 : 0}}
    >
      <div
        className="about__accordion-content-picture"
        style={{
          backgroundImage: `url(/assets/images/${accordionImageNames[openedFieldIndex]})`,
        }}
      />
    </div>
  );

  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    setInitialHeight(wrapperRef.current?.children[0].clientHeight ?? 0);
  }, [zoom, openedFieldIndex]);

  const minHeight = useMemo(() => {
    return isFF ? initialHeight * zoom || 'auto' : 'auto';
  }, [isFF, initialHeight, zoom]);

  const aboutSectionsList = aboutSections.map(section => {
    return (
      <div
        key={section.title}
        className={clsx('about__accordion-field', {
          'about__accordion-field--opened': openedFieldIndex === section.index,
        })}
        onClick={() => setOpenedFieldIndex(openedFieldIndex === section.index ? -1 : section.index)}
      >
        <div className="about__accordion-field-title">
          <p>{section.title}</p>
          <button
            type="button"
            aria-label={section.title}
            className="about__accordion-field-button"
          />
        </div>
        <p className="about__accordion-field-content">{section.text}</p>
        {getAccordionContentWrapper()}
      </div>
    );
  });

  return (
    <div id={StartPageSections.ABOUT} className="about" ref={wrapperRef} style={{minHeight}}>
      <div
        className="about__zoom-container"
        style={{...zoomStyle, height: 'auto'}}
        ref={zoomWrapperRef}
      >
        <h2 className="section-heading">О ПРОДУКТЕ</h2>
        <div className="about__accordion-wrapper">
          <div className="about__accordion-fields">{aboutSectionsList}</div>
          {getAccordionContentWrapper()}
        </div>
      </div>
    </div>
  );
};
