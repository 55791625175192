import React, {FC, useCallback} from 'react';
import {MtsDsControlList, MtsDsControlListCheckboxItem} from '@mts-ds/granat-react';
import {AutocompleteDropdown} from '../../shared/components/AutocompleteDropdown/AutocompleteDropdown';
import {FormState} from '../BetaRequestModal/BetaRequestModal';
import {Organization} from '../../shared/models/organization.model';

interface OrganizationSearchProps {
  toggleOrganizationFormField: () => void;
  invalid: boolean;
  organization: FormState['organization'];
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  callback: (...args: any) => void;
  organizations: Organization[];
  renderOrganizationAutocompleteItem: (option: Organization) => React.ReactNode;
  loading: boolean;
  isError: boolean;
  inputError?: string;
  field: string;
}

export const OrganizationSearch: FC<OrganizationSearchProps> = ({
  toggleOrganizationFormField,
  organization,
  invalid,
  organizations,
  setSearchTerm,
  isError,
  inputError,
  loading,
  renderOrganizationAutocompleteItem,
  callback,
  field,
}) => {
  const onSelectValue = useCallback(
    (value: any) => {
      callback(value, field);
    },
    [callback, field],
  );

  return (
    <>
      <MtsDsControlList size="m">
        <MtsDsControlListCheckboxItem
          label="Я сотрудник компании"
          onChange={toggleOrganizationFormField}
          invalid={invalid}
          checked={true}
        />
      </MtsDsControlList>
      {organization && (
        <AutocompleteDropdown
          label="Где вы работаете"
          placeholder="Название компании или ИНН"
          onSearchValueChange={setSearchTerm}
          onSelectValue={onSelectValue}
          autocompleteOptions={organizations}
          customIdProp="inn"
          renderCustomOption={renderOrganizationAutocompleteItem}
          isError={isError}
          loading={loading}
          inputError={inputError}
        />
      )}
    </>
  );
};
